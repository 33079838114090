@import "~src/scss/color";
@import "~src/scss/variables";
@import "~src/scss/mixin";
p{
  color: $innercontent;
}
button:disabled,
button[disabled],
button[disabled]:hover{
    cursor: not-allowed;
  border: 0px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
}
.errorMessage{
  color: red !important;
  font-size: 14px !important;
  font-weight: bold !important;
}
.heading-box {
  text-align: center;
  .pizzaxlheading{
    color: $innerfont;
    margin-bottom: 15px;
  }
  .pizzaxxsheading{
    font-family: $proximablack;
    color: $orangefont;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    &:after{
      content: "";
    position: absolute;
    width: 52px;
    height: 1px;
    background: $orange;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
    }
  }
  .pizzasmheading{
    line-height: 30px;
  }
}

.banner-wrapper{
  position: relative;
  img{
    display: block;
    width: 100%;
  }
  .banner-content-q{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
    color: $white;
    z-index: 1;
    .pizzaxlheading{
      margin-bottom: 15px;
    }
    .pizzalgheading{
      position: relative;
      margin: 0px 0px 27px 0px;
      font-size: 14px;
      padding: 0px 20px 0px 20px;
      color: $orangefont;
      font-family: $proximablack;
      &:after{
        content: "";
        position: absolute;
        width: 52px;
        height: 1px;
        background: $orange;
        bottom: -14px;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }
    .pizzamdheading{
      color: $white;
      margin-bottom: 0px;
      line-height: 30px;
      padding: 0px 20px;
      font-weight: 600;
    }
    @include breakpoint(xs){
      .pizzalgheading{
        line-height: 30px;
      }
    }
  }

  &:after{
    content: " ";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: $black;
    opacity: 0.4;
  }
}

.line-heading{
  color: $orangefont;
  margin-bottom: 30px;
  font-family: $proximablack;
  text-transform: uppercase;
  position: relative;
  &:after{
    content: "";
    position: absolute;
    width: 48px;
    height: 1px;
    background: $orange;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 0px);
  }
}

.heading-box-inner{
  .pizzaxxsheading{
    font-family: $proximablack;
    color: $innerfont;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    text-align: center;
    &:after{
      content: "";
    position: absolute;
    width: 52px;
    height: 1px;
    background: $orange;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
    }
  }
}

.padding-section{
  padding: 70px 70px;
  @include breakpoint(xs){
     padding: 30px 15px;
    }
}
.padding-top{
 padding-top: 40px;
}




.inner-main{
  padding-left: 250px;
  .container{
    padding: 0 60px;
  }
  @include breakpoint(sm){
    padding: 0px;
    display: block;
  }
}


.step-container{
  width: 450px;
  margin: 0px auto;
  .steps-sucess{
    .heading-box{
      .pizzaxxsheading{
        color: $innerfont;
      }
   }
  }
  .list-p{
    p{
      position: relative;
      padding-left: 15px;
      margin-bottom: 10px;
      line-height: 30px;
      &:after{
        content: '';
        position: absolute;
        width: 6px;
        left: 0px;
        top:12px;
        height: 6px;
        background:$innerfont;
        border-radius: 30px;
      }
      &:last-child{
        margin-bottom: 0px;
      }
    }
  }
  @include breakpoint(xs){
    width: 100%;
  }

}


.about-accordion{
  ul{
      li{
          border-bottom: 2px solid $innerfont;
          -ms-transition: border-color 0.15s ease;
          transition: border-color 0.15s ease;
          -webkit-transition: border-color 0.15s ease;
          margin-bottom: 0!important;
          cursor: pointer;
          .about-accordion-title{
              padding: 30px 70px 30px 0px;
              position: relative;
              -ms-transition: color 0.15s ease;
              transition: color 0.15s ease;
              -webkit-transition: color 0.15s ease;
          }
          .pizzaxxsheading{
              font-size: 16px;
              font-family: $proximarg;
              color: $innerfont;
              line-height: 32px;
          }
          .plus-icon{
              width: 30px;
              height: 30px;
              position: absolute;
              right: 0;
              top: 50%;
              border-radius: 50%;
              border: 2px solid $graytext;
              transform: translate(0px, -50%);
              &::after, &::before{
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  width: 2px;
                  height: 15px;
                  background: $graytext;
              }
              &::before{
                  width: 15px;
                  height: 2px;
              }
          }
          .about-accordion-content.panel{
            display: none;
          }
          .about-accordion-content{
              padding-bottom: 30px;
              
              p{
                  font-size: 16px;
                  font-family: $proximarg;
                  color: $innerfont;
                  line-height: 32px;
              }
              @include breakpoint(xs){
                p{
                  margin-bottom: 30px;
                }
              }

          }
          &.active{
              .pizzaxxsheading{
                  color: $orange;
              }
              .plus-icon{
                  border-color: $orange;
                  &::after{
                      display: none;
                  }
                  &::before{
                      background: $orange;
                  }
              }
          }
          &:hover{
              .pizzaxxsheading{
                  color: $orange;
              }
              .plus-icon{
                  border-color: $orange;
                  &::before, &::after{
                      background: $orange;
                  }
              }
          }
      }
  }
  @include breakpoint(xs){
    width: 100%;
  }
}

/*steps---start---here---*/
.steps-sucess{
  .hading-bold{
    font-family: $proximablack;
    margin-bottom: 15px;
    line-height: 30px;
    margin-top: 20px;
    &:last-child{
      margin-top: 0px;
    }
  }
  .inner-list-wrpr{
    &:last-child{
      margin-top: 20px;
    }
  }

  .about-accordion{
    .about-accordion-content{
      display: flex;
      justify-content: space-between;
      .icon-left-accordian{
        width: 30px;
        margin-top: 8px;
      }
      .content-right-acc{
        width: calc(100% - 30px);
        padding-left: 15px;
      }
    }
  }
}

.meat-the-teams {
  .about-accordion{
    .about-accordion-content{
      .flx-acc-bx{
        display: flex;
        justify-content: space-between;
        padding:30px 0px;
        &:last-child{
          padding-bottom: 0px;
        }
        .acc-bx-left{
          width: 30px;
        }
        .acc-bx-right{
          width: calc(100% - 30px);
            padding-left: 15px;
            .pizzaxxsheading{
              font-weight: bold;
              color: $innercontent;
            }
        }
      }
      .flx-acc-bx2{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding:30px 0px;
        &:last-child{
          padding-bottom: 0px;
        }
        &:last-child{
          padding-top: 0px;
        }
        .acc-bx-left2{
          width: 150px;
        }
        .acc-bx-right2{
          width: calc(100% - 150px);
            padding-left: 15px;
            .pizzaxxsheading{
              font-weight: bold;
              color: $innercontent;
            }
            p{
              margin-bottom: 20px;
              &:last-child{
                margin-bottom: 0px;
              }
            }
            @include breakpoint(xs){
              width: 100%;
              padding-left: 0px;
              padding-top: 15px;p{
                margin-bottom: 10px;
              }
            }
        }
      }
    }
  }
}
.get-answer{
  background:$graybg;
  .about-accordion{
    width: 450px;
    margin: 0px auto;
  }
  @include breakpoint(xs){
    .about-accordion{
      width: 100%;
   }
  }
}

.section-services{
  background:$graybg;
  }
  .box-inner{
    padding: 30px 20px;
    background: $white;
    cursor: pointer;
    &:hover{
      background: #d7dbde;
    }
    .icon-bx{
      width: 40px;
      margin:0px auto 20px auto;
      display: block;
    }
    .pizzasmheading{
      text-align: center;
      font-family: $proximablack;
      color:$innerfont;
      margin-bottom: 15px;
    }
    p{
      text-align: center;
      margin-bottom: 20px;
    }
    .arrow-right{
      width: 30px;
      margin:0px auto;
      display: block;
    }

    @include breakpoint(lg){
      p{
        height:38px;
      }
    }
    @include breakpoint(md){
      margin-bottom: 20px;
      background: #d7dbde;
      p{
        height: auto;
      }
    }

  }

  .section-services2{
    background:$graybg;
    .box-inner{
      display: block;
      width: 100%;
      .icon-bx{
        width: 100px;
        margin-bottom: 0px;
      }
    }
  }

  .form-wraper{
    .input-box-c{
      label{
        margin-bottom: 5px;
        span{
          color: $orangefont;
          font-weight: bold;
          display: inline-block;
          font-size: 18px;
          font-family: $proximablack;
        }
        }
        input{
          display: block;
          width: 100%;
          border: none;
          background: $lightgraybg;
          border-bottom: 2px solid $grayborder;
          padding: 15px 15px;
          margin-bottom: 15px;
          &:focus{
            outline: none;
            border-bottom: 2px solid $orangeborder;
          }         ;
        }
        select{
          display: block;
          width: 100%;
          border: none;
          background: $lightgraybg;
          border-bottom: 2px solid $grayborder;
          padding: 15px 15px;
          margin-bottom: 15px;
          &:focus{
            outline: none;
            border-bottom: 2px solid $orangeborder;
          }
        }
        .pizzaxxsheading{
          font-family: $proximablack;
          color: $innerfont;
          margin:20px 0px;
        }
        p{
          font-family: $proximablack;
          color: $innerfont;
          margin:20px 0px;
          text-align: center;
        }
        button{
          padding: 15px 40px;
          background: $orange;
          color: $white;
          border:none;
          display: inline-block;
          &:focus{
            outline: none;
          }
        }
    }
    .captcha-input{
        width: 130px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    .captcha-img{
      width: 120px;
      display: block;
      margin:0px auto;
    }
  }
 




