.content {
  display: none;
  padding: 10px 20px;
}

.content.active {
  display: block;
}

p{
  color: $innercontent;
}
.inner-main{
  padding-left: 250px;
  .container{
    padding: 0 60px;
  }
  @include breakpoint(sm){
    padding: 0px;
    display: block;
  }
}

.line-heading{
  color: $orangefont;
  margin-bottom: 30px;
  font-family: $proximablack;
  text-transform: uppercase;
  position: relative;
  &:after{
    content: "";
    position: absolute;
    width: 48px;
    height: 1px;
    background: $orange;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 0px);
  }
}

.inerbanner{
  position: relative;
  &:after{
    content: " ";
    position: absolute;
    background: rgba(0,0,0,.2);
    top: 0;
    left: 0;
    right: 0px;
    bottom: 0px;
  }
  .innerbanner-content{
     text-align: center;
     position: absolute;
     top:12%;
     left: 50%;
     transform: translate(-50%, 0px);
     width: 90%;
     margin:0px auto;
     z-index: 1;
    .pizzaxlheading{
      margin-bottom: 40px;
    }
    p{
      color: $white;
      font-size: 22px;
      line-height: 34px;
      padding: 0px 20px;
      font-weight: 600;
      font-family: $proximarg;
    }
    @include breakpoint(md){
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
  }
  @include breakpoint(xs){
    top:5%;
    left: 50%;
    transform: translate(-50%, 0px);
    p{
      font-size: 18px;
      line-height: 28px;
    }
  }

    @include breakpoint(xxxs){
      width: 100%;
      padding: 0px 15px;
      p{
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

}

/*---about--our--food--start--here---*/
.about-our-food{
  padding: 66px;
  background-color: $darkgraybg;
  .line-heading{
     text-align: center;
  }
  .pizzaxlheading{
    color:$innerfont;
    text-align: center;
    line-height: 48px;
  }
  p{
    color:$innercontent;
    line-height:33px;
    width: 60.3%;
    margin: 10px auto 30px auto;
  }
  @include breakpoint(sm){
    p{
      width: 100%;
    }
  }
  @include breakpoint(xs){
    padding: 30px 15px;
    p{
      margin-top: 0px;
    }
  }
}
.about-services{
  display: block;
  width: 100%;
  position: relative;
  img{
    display: block;
    width: 100%;
  }
  .pizzaxxsheading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
    width: 100%;
    text-align: center;
    color: $white;
    z-index: 2;
    text-transform: uppercase;
    font-family: $proximablack;
  }
  &:after{
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: $black;
    opacity: 0.2;
    display: none;
    z-index: 1;
  }
  &:hover{
    &:after{
      display: block;
    }
  }
  @include breakpoint(xs){
      margin-bottom: 15px;
  }
}

/*---OUR QUALITY--start--here---*/
.banner-wrapper{
  position: relative;
  img{
    display: block;
    width: 100%;
  }
  .banner-content-q{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
    color: $white;
    z-index: 1;
    .pizzaxlheading{
      position: relative;
      margin-bottom: 0px;
      &:after{
        content: "";
        position: absolute;
        width: 52px;
        height: 1px;
        background: $orange;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }
    .pizzalgheading{
      color: $white;
      margin:35px 0px 45px 0px;
      font-family: $proximablack;
    }
    .pizzamdheading{
      color: $white;
      margin-bottom: 0px;
      line-height: 30px;
    }
    @include breakpoint(xs){
      .pizzalgheading{
        line-height: 30px;
      }
    }
  }

  &:after{
    content: " ";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: $black;
    opacity: 0.4;
  }
}

/*---our-quality--services--start--here---*/
.row-quality{
    align-items: center;
    padding: 70px 0px;
    margin: 0px auto;
    width: 90%;
    text-align: left;
    .quality-content-bx{
      text-align: center;
    }
    .align-right{
      text-align: right;
    }
  img{
    max-width: 90%;
  }
  .pizzaxlheading{
    color: $innerfont;
    padding-bottom: 30px;
    position: relative;
    &:after{
      content: "";
      position: absolute;
      width: 48px;
      height: 1px;
      background: #EF5A0D;
      bottom: 18px;
      left: 50%;
      transform: translate(-50%, 0px);
    }
  }
  .pizzaxxsheading{
    color: $innerfont;
    font-family: $proximablack;
    margin-bottom: 30px;
  }
  .q-inner-content{

    p{
      margin-bottom: 27px;
      line-height: 32px;
      &:last-child{
        margin-bottom: 0px;
      }
      a{
        color: $orangefont;
      }
    }
    .pizzaxxsheading{
      color: $orangefont;
      margin-bottom: 6px;
    }
  }
  @include breakpoint(xs){
   padding: 30px 0px;
   width: 100%;
   img{
    margin-bottom: 20px;
  }
  .order-set:nth-of-type(1){
    order: 2;
  }
  .order-set:nth-of-type(2){
    order: 1;
  }
   .align-right{
      text-align: center;
    }

   .pizzaxlheading{
     padding-bottom: 5px;
     &:after{
       bottom: -11px;
     }
   }
}
}

.antibiotics-faq{
    margin: 0px auto;
    width: 90%;
    b{
      font-weight: bold;
    }
    .about-accordion{
      width: 85%;
    }
    @include breakpoint(sm){
      width: 100%;
      padding: 0px 70px;
      .about-accordion{
        width: 100%;
      }
      @include breakpoint(xs){
        padding: 0px 15px;
      }
    }
}

/*---our-quality--services-list--start--here---*/
.services-list{
  .row-quality{
    border-bottom: 1px solid #dedede;
    &:last-child{
      border-bottom: none;
      .quality-content-bx{
        text-align: left;
        .pizzaxlheading{
          display: inline-block;
          &:after{
            left: 0px;
            transform: translate(0px, 0px);
          }
        }
      }
    }
  }
}


/*---NUTRITION--start--here---*/
.nutration-tab-wraper{
  padding: 60px 0px 70px 0px;
  width: 90%;
  margin:0px auto;
  @include breakpoint(xs){
    padding: 20px 15px 30px 15px;
    width: 100%;
  }
}
.tab-wraper-list{
   text-align: center;
   border-bottom: 1px solid #dedede;
  li{
    display: inline-block;
    a{
      padding: 10px 15px 20px 15px;
      color: $innercontent;
      &:hover{
        opacity: 0.8;
      }
    }
    .active{
     border-bottom: 3px solid $orangeborder;
    }
  }
  @include breakpoint(xs){
    a{
      padding: 10px 15px 15px 15px;
    }
  }
}

.heading-box {
  text-align: center;
  .pizzaxlheading{
    color: $innerfont;
    margin-bottom: 15px;
  }
  .pizzaxxsheading{
    font-family: $proximablack;
    color: $orangefont;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    &:after{
      content: "";
    position: absolute;
    width: 52px;
    height: 1px;
    background: $orange;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
    }
  }
}
.tab-content-inner{
  padding: 70px 0px 0px 0px;
  .ingredients-faq{
    padding: 15px 0px 50px 0px;
  }
  .gluten-free-faq{
    padding: 15px 0px 50px 0px;
  }
  .gluten-c{
    text-align: center;
    img{
      width: 60%;
      margin: 0px auto;
    }
  }

  p{
    margin-bottom: 20px;
    line-height: 30px;
    &:last-child{
      margin-bottom: 0px;
    }
    a{
      color:$orangefont;
    }
  }
  b{
    font-weight: bold;
    color: $innerfont;
  }

  .req-h{
    margin: 25px 0px 8px 0px;
  }
  .delicious-services{

    .row{
      margin: 40px 0px;
    }
    .delicus-colm{
      position: relative;
      padding: 0px;
      a{
        display: block;
        img{
          display: block;
          width: 100%;
        }
      }
      .pizzamdheading{
        position: absolute;
        top: 50%;
        left: 0px;
        right:0px;
        padding: 0px 5px;
        width: 100%;
        transform: translate(0px, -59%);
        font-family: $proximablack;
        color: $white;
        text-align: center;
        text-transform: uppercase;
        z-index: 1;
      }
      &:after{
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background: #000;
        opacity: 0.2;
        display: none;
        transition: all 0.3s;
      }
      &:hover{
          &:after{
           display: block;
          }

      }
    }
    @include breakpoint(xs){
      .row{
        margin: 30px 0px;
      }
    }
  }
  @include breakpoint(xs){
    padding-top: 30px;
    .row{
      margin: 30px 0px;
    }
  }
}
.allergy-info-content{
  .row{
    margin: 30px 0px;
    .allergy-box-c{
      .pizzamdheading{
        text-align: center;
        font-family: $proximablack;
        color: $innerfont;
        margin-bottom: 20px;
      }
    }
    @include breakpoint(xs){
      .img-dirction{
        text-align: center;
        img{
          margin-bottom: 25px;
        }
      }
    }
  }
}

/*---menu-faq--start--here---*/
.menu-faq{
  padding: 66px;
  background: $footergray;
  .pizzaxlheading{
    text-align: center;
    color: $innerfont;
    margin-bottom: 15px;
    position: relative;
    padding-bottom: 15px;
    &:after{
    content: "";
    position: absolute;
    width: 52px;
    height: 1px;
    background: #EF5A0D;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
    }
  }
  .pizzasmheading{
    text-align: center;
    width: 60%;
    margin:0px auto;
    line-height: 30px;
  }
  .about-accordion{
    width: 60%;
    margin: 0px auto;
  }
  @include breakpoint(xs){
    padding: 30px 15px;
    .pizzasmheading{
      width: 100%;
    }
    .about-accordion{
      width: 100%;
    }
  }
}
.about-accordion{
  ul{
      li{
          border-bottom: 2px solid $innerfont;
          -ms-transition: border-color 0.15s ease;
          transition: border-color 0.15s ease;
          -webkit-transition: border-color 0.15s ease;
          margin-bottom: 0!important;
          cursor: pointer;
          .about-accordion-title{
              padding: 30px 70px 30px 0px;
              position: relative;
              -ms-transition: color 0.15s ease;
              transition: color 0.15s ease;
              -webkit-transition: color 0.15s ease;
          }
          .pizzaxxsheading{
              font-size: 16px;
              font-family: $proximarg;
              color: $innerfont;
              line-height: 32px;
          }
          .plus-icon{
              width: 30px;
              height: 30px;
              position: absolute;
              right: 0;
              top: 50%;
              border-radius: 50%;
              border: 2px solid $graytext;
              transform: translate(0px, -50%);
              &::after, &::before{
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  width: 2px;
                  height: 15px;
                  background: $graytext;
              }
              &::before{
                  width: 15px;
                  height: 2px;
              }
          }
          .about-accordion-content{
              padding-bottom: 30px;
              p{
                  font-size: 16px;
                  font-family: $proximarg;
                  color: $innerfont;
                  line-height: 32px;
              }
              @include breakpoint(xs){
                p{
                  margin-bottom: 30px;
                }
              }

          }
          &.active{
              .pizzaxxsheading{
                  color: $orange;
              }
              .plus-icon{
                  border-color: $orange;
                  &::after{
                      display: none;
                  }
                  &::before{
                      background: $orange;
                  }
              }
          }
          &:hover{
              .pizzaxxsheading{
                  color: $orange;
              }
              .plus-icon{
                  border-color: $orange;
                  &::before, &::after{
                      background: $orange;
                  }
              }
          }
      }
  }
  @include breakpoint(xs){
    width: 100%;
  }
}


// privacy policy
p{
  color: $innercontent;
}
.heading-box {
  text-align: center;
  .pizzaxlheading{
    color: $innerfont;
    margin-bottom: 15px;
  }
  .pizzaxxsheading{
    font-family: $proximablack;
    color: $orangefont;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    &:after{
      content: "";
    position: absolute;
    width: 52px;
    height: 1px;
    background: $orange;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
    }
  }
  .pizzasmheading{
    line-height: 30px;
  }
}

.banner-wrapper{
  position: relative;
  img{
    display: block;
    width: 100%;
  }
  .banner-content-q{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
    color: $white;
    z-index: 1;
    .pizzaxlheading{
      margin-bottom: 15px;
    }
    .pizzalgheading{
      position: relative;
      margin: 0px 0px 27px 0px;
      font-size: 14px;
      padding: 0px 20px 0px 20px;
      color: $orangefont;
      font-family: $proximablack;
      &:after{
        content: "";
        position: absolute;
        width: 52px;
        height: 1px;
        background: $orange;
        bottom: -14px;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }
    .pizzaxlheading{
      position: relative;
      &:after{
        content: "";
        position: absolute;
        width: 52px;
        height: 1px;
        background: $orange;
        bottom: -14px;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }
    .pizzamdheading{
      color: $white;
      margin-bottom: 0px;
      line-height: 30px;
      padding: 0px 20px;
      font-weight: 600;
    }
    @include breakpoint(xs){
      .pizzalgheading{
        line-height: 30px;
      }
    }
  }

  &:after{
    content: " ";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: $black;
    opacity: 0.4;
  }
}

.line-heading{
  color: $orangefont;
  margin-bottom: 30px;
  font-family: $proximablack;
  text-transform: uppercase;
  position: relative;
  &:after{
    content: "";
    position: absolute;
    width: 48px;
    height: 1px;
    background: $orange;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 0px);
  }
}

.heading-box-inner{
  .pizzaxxsheading{
    font-family: $proximablack;
    color: $innerfont;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    text-align: center;
    &:after{
      content: "";
    position: absolute;
    width: 52px;
    height: 1px;
    background: $orange;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
    }
  }
}

.padding-section{
  padding: 70px 70px;
  @include breakpoint(xs){
     padding: 30px 15px;
    }
}
.padding-top{
 padding-top: 40px;
}




.inner-main{
  padding-left: 250px;
  .container{
    padding: 0 60px;
  }
  @include breakpoint(sm){
    padding: 0px;
    display: block;
  }
}

.privacy-policy{
  width: 75%;
  margin:0px auto;
  h2{
    color:$innerfont;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  p{
    margin-bottom: 30px;
    &:last-child{
      margin-bottom: 0px;
    }
    a{
      color: $innercontent;
      &:hover{
        color: $orangefont;
      }
    }
  }
  @include breakpoint(xs){
    width: 100%;
    h2{
      margin-bottom: 20px;
    }
    p{
      margin-bottom: 20px;
    }
  }
}

// term and condition

.terms-and-conditions{
  width: 75%;
  margin:0px auto;
  h2{
    color:$innerfont;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  p{
    margin-bottom: 30px;
    &:last-child{
      margin-bottom: 0px;
    }
  }
  @include breakpoint(xs){
    width: 100%;
    h2{
      margin-bottom: 20px;
    }
    p{
      margin-bottom: 20px;
    }
  }
}
// guarantee-policy
.guarantee-policy{
  width: 75%;
  margin:0px auto;
  h2{
    color:$innerfont;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  p{
    margin-bottom: 30px;
    &:last-child{
      margin-bottom: 0px;
    }
    a{
      color: $innercontent;
      &:hover{
        color: $orangefont;
      }
    }
  }
  @include breakpoint(xs){
    width: 100%;
    h2{
      margin-bottom: 20px;
    }
    p{
      margin-bottom: 20px;
    }
  }
}



.accessibility-policy{
  .about-accordion{
    ul{
      li{
        ul{
          padding-bottom: 40px;
          li{
            list-style: none;
            border:none;
            padding-left: 15px;
            position: relative;
            margin-bottom: 15px !important;
            line-height: 30px;
            color: $innercontent;
            &:after{
              content: ' ';
              position: absolute;
              top: 12px;
              left: 0px;
              width: 6px;
              height: 6px;
              border-radius: 30px;
              background: $innercontent;
            }
          }
        }
      }
    }
  }
}
.heading-box {
  text-align: center;
  .pizzaxlheading{
    color: $innerfont;
    margin-bottom: 15px;
  }
  .pizzaxxsheading{
    font-family: $proximablack;
    color: $orangefont;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    &:after{
      content: "";
    position: absolute;
    width: 52px;
    height: 1px;
    background: $orange;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
    }
  }

  .pizzasmheading{
    line-height: 30px;
  }
}

.banner-wrapper{
  position: relative;
  img{
    display: block;
    width: 100%;
  }
  .banner-content-q{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
    color: $white;
    z-index: 1;
    .pizzaxlheading{
      margin-bottom: 15px;
    }
    .pizzalgheading{
      position: relative;
      margin: 0px 0px 27px 0px;
      font-size: 14px;
      padding: 0px 20px 0px 20px;
      color: $orangefont;
      font-family: $proximablack;
      &:after{
        content: "";
        position: absolute;
        width: 52px;
        height: 1px;
        background: $orange;
        bottom: -14px;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }

    .pizzaxlheading{
      position: relative;
      padding-bottom: 10px;
      &:after{
        content: "";
      position: absolute;
      width: 52px;
      height: 1px;
      background: $orange;
      bottom: 0px;
      left: 50%;
      transform: translate(-50%, 0px);
      }
    }
    .pizzamdheading{
      color: $white;
      margin-bottom: 0px;
      line-height: 30px;
      padding: 0px 20px;
      font-weight: 600;
    }
    @include breakpoint(xs){
      .pizzalgheading{
        line-height: 30px;
      }
    }
  }

  &:after{
    content: " ";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: $black;
    opacity: 0.4;
  }
}

.line-heading{
  color: $orangefont;
  margin-bottom: 30px;
  font-family: $proximablack;
  text-transform: uppercase;
  position: relative;
  &:after{
    content: "";
    position: absolute;
    width: 48px;
    height: 1px;
    background: $orange;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 0px);
  }
}

.heading-box-inner{
  .pizzaxxsheading{
    font-family: $proximablack;
    color: $innerfont;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    text-align: center;
    &:after{
      content: "";
    position: absolute;
    width: 52px;
    height: 1px;
    background: $orange;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
    }
  }
}

.padding-section{
  padding: 70px 70px;
  @include breakpoint(xs){
     padding: 30px 15px;
    }
}
.padding-top{
 padding-top: 40px;
}




.inner-main{
  padding-left: 250px;
  .container{
    padding: 0 60px;
  }
  @include breakpoint(sm){
    padding: 0px;
    display: block;
  }
}

.accessibility-policy{
  width: 75%;
  margin:0px auto;
  border-bottom: 1px solid $grayborder;
  &:last-child{
    border-bottom: none;
  }
  h2{
    color:$innerfont;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  p{
    margin-bottom: 30px;
    &:last-child{
      margin-bottom: 0px;
    }


    a{
      color: $innercontent;
      &:hover{
        color: $orangefont;
      }
    }
  }
  @include breakpoint(xs){
    width: 100%;
    h2{
      margin-bottom: 20px;
    }
    p{
      margin-bottom: 20px;
    }
  }
}


