@import "~src/scss/color";
@import "~src/scss/variables";
@import "~src/scss/mixin";
@import "~src/scss/fonts";
@import "~src/scss/common";
@import "~src/scss/about-us";
@import "~src/scss/franchise";
@import "~src/scss/company";
@import "~src/scss/investors";
@import "~src/scss/foryou";
.pac-container {
    z-index: 100000000 !important;
}