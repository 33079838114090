$white: #ffffff;
$black: #000000;
$link:#3b8af7;
$facebook:#3c5193;
$brownfont:#4C2F15;
$brown:#4A3017;
$orange:#EF5A0D;
$orangefont:#fb5000;
$orangeborder:#F86219;
$graytext:#AAAAAA;
$graybg:#EFEFEF;
$lightgraybg:#EEF1F5;
$darkgraybg:#EDF2F5;
$pink:#EE3E35;
$footergray:#eef2f5;
$grayborder:#dee2e6;
$green:#4e7026;
$deepgray:#6d7273;
$darkgraytext:#5a5f60;
$innerfont:#444;
$innercontent:#676767;
$red:#ff0000;
