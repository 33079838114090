@import "~src/scss/color";
@import "~src/scss/variables";
@import "~src/scss/mixin";
p{
  color: $innercontent;
}
button:disabled,
button[disabled],
button[disabled]:hover{
    cursor: not-allowed;
  border: 0px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
.btn-gift{
  display: inline-block;
  padding: 12px 40px;
  background: $orange;
  border: 1px solid $orange;
  color: $white;
  font-weight: bold;
  transition: all 0.3s;
  // &:hover{
  //   background: $white;
  //   color: $orange;
  // }
}
.errorMessage{
  color: red !important;
  font-size: 14px !important;
  font-weight: bold !important;
}
.heading-box {
  text-align: center;
  .pizzaxlheading{
    color: $innerfont;
    margin-bottom: 15px;
  }
  .pizzaxxsheading{
    font-family: $proximablack;
    color: $orangefont;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    &:after{
      content: "";
    position: absolute;
    width: 52px;
    height: 1px;
    background: $orange;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
    }
  }
  .pizzasmheading{
    line-height: 30px;
  }
}

.banner-wrapper{
  position: relative;
  display: block;
  width: 100%;
  img{
    display: block;
    width: 100%;
  }
  .arrow-link{
    text-align: center;
    img{
      width: 60px;
      display: inline-block;
    }
  }
  .banner-content-q{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
    color: $white;
    z-index: 1;
    .pizzaxlheading{
      position: relative;
      margin-bottom: 0px;
      &:after{
        content: "";
        position: absolute;
        width: 52px;
        height: 1px;
        background: $orange;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }
    .pizzalgheading{
      position: relative;
      margin: 0px 0px 27px 0px;
      font-size: 14px;
      padding: 0px 20px 0px 20px;
      color: $orangefont;
      font-family: $proximablack;
      &:after{
        content: "";
        position: absolute;
        width: 52px;
        height: 1px;
        background: $orange;
        bottom: -14px;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }
    .pizzamdheading{
      color: $white;
      margin-bottom: 0px;
      line-height: 30px;
      padding: 0px 20px;
      font-weight: 600;
    }
    @include breakpoint(xs){
      .pizzalgheading{
        line-height: 30px;
      }
    }
  }

  &:after{
    content: " ";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: $black;
    opacity: 0.4;
  }
}


.line-heading{
  color: $orangefont;
  margin-bottom: 30px;
  font-family: $proximablack;
  text-transform: uppercase;
  position: relative;
  &:after{
    content: "";
    position: absolute;
    width: 48px;
    height: 1px;
    background: $orange;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 0px);
  }
}

.heading-box-inner{
  .pizzaxxsheading{
    font-family: $proximablack;
    color: $innerfont;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    text-align: center;
    &:after{
      content: "";
    position: absolute;
    width: 52px;
    height: 1px;
    background: $orange;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
    }
  }
}

.padding-section{
  padding: 70px 70px;
  @include breakpoint(xs){
     padding: 30px 15px;
    }
}
.padding-top{
 padding-top: 40px;
}

.header-close-layer{
  position: fixed;
  top:0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: $white;
  opacity: 0.9;
  display: none;
  z-index: 999;
  display: none;
  @include breakpoint(sm){
    &.inner-header-show{
      display: block;
    }
  }
}

.about-accordion{
  ul{
      li{
          border-bottom: 2px solid $innerfont;
          -ms-transition: border-color 0.15s ease;
          transition: border-color 0.15s ease;
          -webkit-transition: border-color 0.15s ease;
          margin-bottom: 0!important;
          cursor: pointer;
          .about-accordion-title{
              padding: 30px 70px 30px 0px;
              position: relative;
              -ms-transition: color 0.15s ease;
              transition: color 0.15s ease;
              -webkit-transition: color 0.15s ease;
          }
          .pizzaxxsheading{
              font-size: 16px;
              font-family: $proximarg;
              color: $innerfont;
              line-height: 32px;
          }
          .plus-icon{
              width: 30px;
              height: 30px;
              position: absolute;
              right: 0;
              top: 50%;
              border-radius: 50%;
              border: 2px solid $graytext;
              transform: translate(0px, -50%);
              &::after, &::before{
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  width: 2px;
                  height: 15px;
                  background: $graytext;
              }
              &::before{
                  width: 15px;
                  height: 2px;
              }
          }
          .about-accordion-content{
              padding-bottom: 30px;
              p{
                  font-size: 16px;
                  font-family: $proximarg;
                  color: $innerfont;
                  line-height: 32px;
              }
              @include breakpoint(xs){
                p{
                  margin-bottom: 30px;
                }
              }

          }
          &.active{
              .pizzaxxsheading{
                  color: $orange;
              }
              .plus-icon{
                  border-color: $orange;
                  &::after{
                      display: none;
                  }
                  &::before{
                      background: $orange;
                  }
              }
          }
          &:hover{
              .pizzaxxsheading{
                  color: $orange;
              }
              .plus-icon{
                  border-color: $orange;
                  &::before, &::after{
                      background: $orange;
                  }
              }
          }
      }
  }
  @include breakpoint(xs){
    width: 100%;
  }
}



.inner-main{
  .mobile-header-about{
    padding: 20px 0 15px;
    background: $orange;
    position: sticky;
    top: 0px;
    z-index: 2;
    display: none;
    .row{
      margin: 0px;
      a{
        display: block;
        width: 90%;
      }
    }
    .toggle-sidebar-header{
      display: inline-block;
      margin: 0;
      background: url(../assets/images/sprite-image.png) no-repeat;
      background-position: 0px -144px;
      width: 35px;
      height: 28px;
    }
    @include breakpoint(md){
      display: block;
    }
  }
  padding-left: 250px;
  .container{
    padding: 0 60px;
  }
  @include breakpoint(sm){
    padding: 0px;
    display: block;
  }
}
.leadership-banner{
  padding-top: 15px;
  background: $graybg;
}


.contact-us{
  .contact-contect-box{
    text-align: center;
    p{
      margin-bottom: 15px;
      &:last-child{
        margin-bottom: 0px;
      }
    }
    a{
      color: $orange;
      &:hover{
        color: $innercontent;
      }
    }
    .got-something{
      margin: 30px 0px;
    }
  }
}

.gift-card{
  .pizzaxlheading{
    &:after{
      display: none;
    }
  }
}

.school-lunch-free{
  .banner-lunch-free{
    padding-top: 15px;
    background: $darkgraybg;
    .banner-content-q{
      .pizzaxlheading{
        &:after{
          display: none;
        }
      }
    }
  }
  .content-lunch-program{
    text-align: center;
    padding-top: 70px;
    max-width: 60%;
    margin: 0px auto;
    &:last-child{
      padding-bottom: 70px;
    }
    p{
      line-height: 30px;
      a{
        color: $innercontent;
      }
    }

    .pizzaxlheading{
      color: $innerfont;
    }
    .italic{
      font-style: italic;
    }
    .box-inner{
      padding: 20px 20px 20px 20px;
      .pizzaxxsheading{
        font-weight: bold;
        color: $innerfont;
        margin-bottom: 10px;
      }
    }
    @include breakpoint(md){
      max-width: 100%;
      padding: 30px 15px 0px 15px;
      &:last-child{
        padding-bottom: 30px;
      }
    }
  }
  .banner-program{
    padding-top: 70px;
  }
  .banner-padding{
    padding-top: 50px;
  }
  .nutrional-sec{
    max-width: 799px;
    margin:0px auto;
  }
  .nutrional-sec ul{
    margin-top: 20px;
  }
  .nutrional-sec ul li{
    position: relative;
    padding-left: 15px;
    margin-bottom: 15px;
    color:$innerfont;
  }
  .nutrional-sec ul li:after{
    content: ' ';
    position: absolute;
    top:6px;
    border-radius: 30px;
    left: 0px;
    width: 6px;
    height: 6px;
    background: $innerfont;
  }
  @include breakpoint(xs){
    .nutrional-sec{
      max-width: 100%;
    }
  }
}

.corporate-catering{
  .banner-wrapper{
    .pizzaxlheading{
      &:after{
        display: none;
      }
    }
  }
  .pizzamdheading{
    line-height: 30px;
  }
  .input-box-c{
    margin-top: 20px;
    label{
      margin-bottom: 5px;
      color: $innercontent;
      span{
        color: $orangefont;
        font-weight: bold;
        display: inline-block;
        font-size: 18px;
        font-family: $proximablack;
      }
    }
    input{
      display: block;
    width: 100%;
    border: none;
    background: $lightgraybg;
    border-bottom: 2px solid $grayborder;
    padding: 15px 15px;
    margin-bottom: 15px;
    appearance: none;
    -webkit-appearance: none;
    &:focus{
      border-bottom: 2px solid $orangeborder;
      outline: none;
    }
    }

    input::-webkit-outer-spin-button{
      -webkit-appearance: none;
       margin: 0;
    }
    input::-webkit-inner-spin-button{
      -webkit-appearance: none;
       margin: 0;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }
    textarea{
      display: block;
    width: 100%;
    border: none;
    background: $lightgraybg;
    border-bottom: 2px solid $grayborder;
    padding: 15px 15px;
    margin-bottom: 15px;
    &:focus{
      border-bottom: 2px solid $orangeborder;
      outline: none;
    }
    }
  }
  .catring-inner{
    padding: 70px 0px;
    width: 65%;
    margin: 0px auto;
  }
  .heading-box{
    &:nth-of-type(2){
      margin:50px 0px;
    }
  }
  .call-contact{
    p{
      margin-bottom: 15px;
      strong{
        font-weight: bold;
        margin-right: 8px;
      }
    }
  }
  @include breakpoint(xs){
    .catring-inner{
      width: 100%;
      padding: 30px 15px;
    }
    .heading-box{
      &:nth-of-type(2){
        margin:30px 0px;
      }
    }
    .call-contact{
      margin-top: 30px;
    }
  }
}
.contests{

  .pizzamdheading{
    line-height: 30px;
  }
  .image-left{
    img{
      max-width: 70%;
      margin:0px auto;
      display: block;
    }
  }
  .contest-right-bx{
    .heading-box{
      text-align: left;
      .pizzamdheading{
        margin-bottom: 16px;
      }
    }
    text-align: left;
    p{
      margin-bottom: 16px;
      line-height: 30px;
      strong{
        font-weight: bold;
      }
    }
  }
  @include breakpoint(xs){
    .contest-right-bx{
      text-align: center;
      .heading-box{
        text-align: center;
      }
    }
    .image-left{
      text-align: center;
      img{
        max-width: 40%;
      }
    }
  }
  .redeem-slider-wraper{
    margin-top: 30px;
    position: relative;
    .redeem-iner-iteam{
      padding: 0px 15px;
      p{
        margin-bottom: 0px;
        margin-top: 15px;
      }
    }

  }
  .score-faq{
    padding-top: 0px;
    margin-top: -20px;
  }
}









