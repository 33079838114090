@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('./fonts/ProximaNova-Bold.eot');
    src: url('./fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ProximaNova-Bold.woff2') format('woff2'),
        url('./fonts/ProximaNova-Bold.woff') format('woff'),
        url('./fonts/ProximaNova-Bold.ttf') format('truetype'),
        url('./fonts/ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('./fonts/ProximaNova-Regular.eot');
    src: url('./fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ProximaNova-Regular.woff2') format('woff2'),
        url('./fonts/ProximaNova-Regular.woff') format('woff'),
        url('./fonts/ProximaNova-Regular.ttf') format('truetype'),
        url('./fonts/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Bl';
    src: url('./fonts/ProximaNova-Black.eot');
    src: url('./fonts/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ProximaNova-Black.woff2') format('woff2'),
        url('./fonts/ProximaNova-Black.woff') format('woff'),
        url('./fonts/ProximaNova-Black.ttf') format('truetype'),
        url('./fonts/ProximaNova-Black.svg#ProximaNova-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}