@import "~src/scss/color";
@import "~src/scss/variables";
@import "~src/scss/mixin";
p{
  color: $innercontent;
}
.heading-box {
  text-align: center;
  .pizzaxlheading{
    color: $innerfont;
    margin-bottom: 15px;
  }
  .pizzaxxsheading{
    font-family: $proximablack;
    color: $orangefont;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    &:after{
      content: "";
    position: absolute;
    width: 52px;
    height: 1px;
    background: $orange;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
    }
  }
  .pizzasmheading{
    line-height: 30px;
  }
}

.banner-wrapper{
  position: relative;
  display: block;
  width: 100%;
  img{
    display: block;
    width: 100%;
  }
  .arrow-link{
    text-align: center;
    img{
      width: 60px;
      display: inline-block;
    }
  }
  .banner-content-q{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
    color: $white;
    z-index: 1;
    .pizzaxlheading{
      margin-bottom: 15px;
    }
    .pizzalgheading{
      position: relative;
      margin: 0px 0px 27px 0px;
      font-size: 14px;
      padding: 0px 20px 0px 20px;
      color: $orangefont;
      font-family: $proximablack;
      &:after{
        content: "";
        position: absolute;
        width: 52px;
        height: 1px;
        background: $orange;
        bottom: -14px;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }
    .pizzamdheading{
      color: $white;
      margin-bottom: 0px;
      line-height: 30px;
      padding: 0px 20px;
      font-weight: 600;
    }
    @include breakpoint(xs){
      .pizzalgheading{
        line-height: 30px;
      }
    }
  }

  &:after{
    content: " ";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: $black;
    opacity: 0.4;
  }
}

.line-heading{
  color: $orangefont;
  margin-bottom: 30px;
  font-family: $proximablack;
  text-transform: uppercase;
  position: relative;
  &:after{
    content: "";
    position: absolute;
    width: 48px;
    height: 1px;
    background: $orange;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 0px);
  }
}

.heading-box-inner{
  .pizzaxxsheading{
    font-family: $proximablack;
    color: $innerfont;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    text-align: center;
    &:after{
      content: "";
    position: absolute;
    width: 52px;
    height: 1px;
    background: $orange;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
    }
  }
}

.padding-section{
  padding: 70px 70px;
  @include breakpoint(xs){
     padding: 30px 15px;
    }
}
.padding-top{
 padding-top: 40px;
}




.inner-main{
  padding-left: 250px;
  .container{
    padding: 0 60px;
  }
  @include breakpoint(sm){
    padding: 0px;
    display: block;
  }
}
.leadership-banner{
  padding-top: 15px;
  background: $graybg;
}

.about-accordion{
  ul{
      li{
          border-bottom: 2px solid $innerfont;
          -ms-transition: border-color 0.15s ease;
          transition: border-color 0.15s ease;
          -webkit-transition: border-color 0.15s ease;
          margin-bottom: 0!important;
          cursor: pointer;
          .about-accordion-title{
              padding: 30px 70px 30px 0px;
              position: relative;
              -ms-transition: color 0.15s ease;
              transition: color 0.15s ease;
              -webkit-transition: color 0.15s ease;
          }
          .pizzaxxsheading{
              font-size: 16px;
              font-family: $proximarg;
              color: $innerfont;
              line-height: 32px;
          }
          .plus-icon{
              width: 30px;
              height: 30px;
              position: absolute;
              right: 0;
              top: 50%;
              border-radius: 50%;
              border: 2px solid $graytext;
              transform: translate(0px, -50%);
              &::after, &::before{
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  width: 2px;
                  height: 15px;
                  background: $graytext;
              }
              &::before{
                  width: 15px;
                  height: 2px;
              }
          }
          .about-accordion-content{
              padding-bottom: 30px;
              p{
                  font-size: 16px;
                  font-family: $proximarg;
                  color: $innerfont;
                  line-height: 32px;
              }
              @include breakpoint(xs){
                p{
                  margin-bottom: 30px;
                }
              }

          }
          &.active{
              .pizzaxxsheading{
                  color: $orange;
              }
              .plus-icon{
                  border-color: $orange;
                  &::after{
                      display: none;
                  }
                  &::before{
                      background: $orange;
                  }
              }
          }
          &:hover{
              .pizzaxxsheading{
                  color: $orange;
              }
              .plus-icon{
                  border-color: $orange;
                  &::before, &::after{
                      background: $orange;
                  }
              }
          }
      }
  }
  @include breakpoint(xs){
    width: 100%;
  }
}

.come-work-here{
  background: $graybg;
  .padding-section{
    padding-bottom: 0px;
  }
  .container-come-work-here{
    max-width: 70%;
    margin:0px auto;
    .bottom-pizaa-img{
      text-align: center;
      padding-top: 70px;
    }
  }
  .heading-box{
    .pizzasmheading{
      margin-bottom: 20px;
      font-size: 16px;
    }
  }
  .recipe-accordian{
     margin-bottom: 20px;
    .pizzasmheading{
      font-family: $proximablack;
      color: $innercontent;
      font-weight: bold;
      margin-bottom: 20px;
    }
    a{
      color: $innercontent;
      display: inline-block;
      margin-top:15px;
      img{
        display: inline-block;
        width: 30px;
        margin-left: 5px;
        transition: all 0.5s;
      }
      &:hover{
        color: $orangefont;
        img{
          margin-left: 10px;
        }
      }
    }
  }

  .about-our-food{
    background: $white;
    padding: 70px 70px;
    .about-services{
      display: block;
      width: 100%;
      position: relative;
      img{
        display: block;
        width: 100%;
      }
      .layer-div{
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.4);
        &:hover{
            background: rgba(0, 0, 0, 0.2);
        }
        .icon-right{
          max-width: 40px;
          display: block;
          margin:0px auto;
        }
      }
      .pizzaxxsheading{
        color: $white;
        z-index: 2;
        text-transform: uppercase;
        font-family: $proximablack;
      }


      @include breakpoint(xs){
          margin-bottom: 15px;
      }
    }
    @include breakpoint(xs){
      padding: 30px 15px;
  }
  }

  @include breakpoint(md){
    .container-come-work-here{
      max-width: 100%;
      .bottom-pizaa-img{
        padding-top: 30px;
      }
    }
  }
}


.cominity-wraper{
  background:$darkgraybg;
  padding: 70px 70px 30px 70px;
  .bx-comunity{
    text-align: center;
    .comunity-content-bx{
      padding-top: 15px;
      margin-bottom: 40px;
      .pizzaxsheading{
        font-weight: bold;
        color: $orangefont;
        margin-bottom: 8px;
      }
    }

  }

  @include breakpoint(xs){
    padding: 30px 15px 0px 15px;
    .comunity-content-bx{
      margin-bottom: 30px;
    }
}
}


.slice-smile-section{
  .banner-wrapper{
    .banner-content-q{
      .pizzaxlheading{
        margin-bottom: 0px;
      }
    }
  }
  .mercal-network{
    padding-top: 70px;
  }
  .heading-box .btn-page {
    margin-top: 20px;
    a{
      font-size: 18px;
      font-weight: bold;
      background: $orange;
      color: $white;
      border: 1px solid $orangeborder;
      border-radius: 5px;
      padding: 0 35px;
      height: 45px;
      line-height: 45px;
      text-decoration: none;
      text-transform: uppercase;
      transition: ease-in-out 0.5s;
      -webkit-transition: ease-in-out 0.5s;
      &:hover{
        background: $white;
        color: $orangefont;
      }
    }
  }
  .rasing-box {
    text-align: center;
    p{
      margin-bottom: 15px;
      a{
        color: $orangefont;
        &:hover{
          color: $innercontent;
        }
      }
    }
    .detail-network {
      text-align: left;
      .pizzamdheading{
        font-weight: bold;
        margin:30px 0px 15px 0px;
      }
      p{
        strong{
          font-weight: bold;
        }
      }
    }
  }
  .rasing-right-box{
    padding-left: 40px;
  }
  @include breakpoint(xs){
    .mercal-network{
      padding-top: 30px;
    }
    .rasing-right-box{
      padding-left: 0px;
    }

  }
}

.envoirment-section{
  background: $darkgraybg;
  .container{
    max-width: 800px;
    margin:0px auto;
  }
  .heading-box{
    &:first-child{
      padding-bottom: 40px;
      border-bottom: 2px solid $innerfont;
      margin-bottom: 40px;
    }
    .pizzaxlheading{
      position: relative;
      padding-bottom: 30px;
      &:after{
        content: '';
        position: absolute;
        width: 52px;
        height: 1px;
        left: 50%;
        bottom: 10px;
        transform: translate(-50%, 0px);
        background: $orange;
      }
    }
    p{
      text-align: left;
      line-height: 30px;
    }
    .list-green-energy{
      .pizzaxsheading{
        margin:40px 0px 20px 0px;
        font-weight: bold;
        color: $innerfont;
        text-align: left;
      }
      p{
        position: relative;
        margin-bottom: 15px;
        padding-left: 15px;
        &:last-child{
          margin-bottom: 0px;
        }
        &:after{
          content: ' ';
          position: absolute;
          top: 10px;
          left: 0px;
          width: 6px;
          height: 6px;
          border-radius: 30px;
          background: $innercontent;
        }
      }
    }
  }
  @include breakpoint(md){
    .container{
      width: 100%;
      max-width: 100%;
      padding: 0px;
    }
  }
}

.company-news{
  .banner-wrapper{
    .pizzaxlheading{
      padding-bottom: 30px;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        width: 52px;
        height: 1px;
        left: 50%;
        bottom: 10px;
        transform: translate(-50%, 0px);
        background: $orange;
      }
    }
  }
  .accordian-news{
    .about-accordion{
      max-width: 700px;
      margin: 0px auto;
      padding-top: 70px;
      padding-bottom: 70px;
      .recipe-accordian{
        margin-bottom: 20px;
        &:last-child{
          margin-bottom: 0px;
        }
       .pizzasmheading{
         font-family: $proximablack;
         color: $innercontent;
         font-weight: bold;
         margin-bottom: 20px;
       }
       a{
         color: $innercontent;
         display: inline-block;
         margin-top:15px;
         img{
           display: inline-block;
           width: 30px;
           margin-left: 5px;
           transition: all 0.5s;
         }
         &:hover{
           color: $orangefont;
           img{
             margin-left: 10px;
           }
         }
       }
     }
     @include breakpoint(xs){
      max-width: 100%;
      padding: 0px 15px 30px 15px;
    }
    }
  }
}





