@import "~src/scss/color";
@import "~src/scss/variables";
@import "~src/scss/mixin";
p{
  color: $innercontent;
}
.heading-box {
  text-align: center;
  .pizzaxlheading{
    color: $innerfont;
    margin-bottom: 15px;
  }
  .pizzaxxsheading{
    font-family: $proximablack;
    color: $orangefont;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    &:after{
      content: "";
    position: absolute;
    width: 52px;
    height: 1px;
    background: $orange;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
    }
  }
  .pizzasmheading{
    line-height: 30px;
  }
}

.banner-wrapper{
  position: relative;
  display: block;
  width: 100%;
  img{
    display: block;
    width: 100%;
  }
  .arrow-link{
    text-align: center;
    img{
      width: 60px;
      display: inline-block;
    }
  }
  .banner-content-q{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
    color: $white;
    z-index: 1;
    .pizzaxlheading{
      position: relative;
      margin-bottom: 0px;
      &:after{
        content: "";
        position: absolute;
        width: 52px;
        height: 1px;
        background: $orange;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }
    .pizzalgheading{
      position: relative;
      margin: 0px 0px 27px 0px;
      font-size: 14px;
      padding: 0px 20px 0px 20px;
      color: $orangefont;
      font-family: $proximablack;
      &:after{
        content: "";
        position: absolute;
        width: 52px;
        height: 1px;
        background: $orange;
        bottom: -14px;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }
    .pizzamdheading{
      color: $white;
      margin-bottom: 0px;
      line-height: 30px;
      padding: 0px 20px;
      font-weight: 600;
    }
    @include breakpoint(xs){
      .pizzalgheading{
        line-height: 30px;
      }
    }
  }

  &:after{
    content: " ";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: $black;
    opacity: 0.4;
  }
}

.recipe-accordian{
  margin-bottom: 20px;
  &:last-child{
    margin-bottom: 0px;
  }
 .pizzasmheading{
   font-family: $proximablack;
   color: $innercontent;
   font-weight: bold;
   margin-bottom: 20px;
 }
 a{
   color: $innercontent;
   display: inline-block;
   margin-top:15px;
   img{
     display: inline-block;
     width: 30px;
     margin-left: 5px;
     transition: all 0.5s;
   }
   &:hover{
     color: $orangefont;
     img{
       margin-left: 10px;
     }
   }
 }
}

.line-heading{
  color: $orangefont;
  margin-bottom: 30px;
  font-family: $proximablack;
  text-transform: uppercase;
  position: relative;
  &:after{
    content: "";
    position: absolute;
    width: 48px;
    height: 1px;
    background: $orange;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 0px);
  }
}

.heading-box-inner{
  .pizzaxxsheading{
    font-family: $proximablack;
    color: $innerfont;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    text-align: center;
    &:after{
      content: "";
    position: absolute;
    width: 52px;
    height: 1px;
    background: $orange;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
    }
  }
}

.padding-section{
  padding: 70px 70px;
  @include breakpoint(xs){
     padding: 30px 15px;
    }
}
.padding-top{
 padding-top: 40px;
}

.header-close-layer{
  position: fixed;
  top:0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: $white;
  opacity: 0.9;
  display: none;
  z-index: 999;
  display: none;
  @include breakpoint(sm){
    &.inner-header-show{
      display: block;
    }
  }
}

.about-accordion{
  ul{
      li{
          border-bottom: 2px solid $innerfont;
          -ms-transition: border-color 0.15s ease;
          transition: border-color 0.15s ease;
          -webkit-transition: border-color 0.15s ease;
          margin-bottom: 0!important;
          cursor: pointer;
          .about-accordion-title{
              padding: 30px 70px 30px 0px;
              position: relative;
              -ms-transition: color 0.15s ease;
              transition: color 0.15s ease;
              -webkit-transition: color 0.15s ease;
          }
          .pizzaxxsheading{
              font-size: 16px;
              font-family: $proximarg;
              color: $innerfont;
              line-height: 32px;
          }
          .plus-icon{
              width: 30px;
              height: 30px;
              position: absolute;
              right: 0;
              top: 50%;
              border-radius: 50%;
              border: 2px solid $graytext;
              transform: translate(0px, -50%);
              &::after, &::before{
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  width: 2px;
                  height: 15px;
                  background: $graytext;
              }
              &::before{
                  width: 15px;
                  height: 2px;
              }
          }
          .about-accordion-content{
              padding-bottom: 30px;
              p{
                  font-size: 16px;
                  font-family: $proximarg;
                  color: $innerfont;
                  line-height: 32px;
              }
              @include breakpoint(xs){
                p{
                  margin-bottom: 30px;
                }
              }

          }
          &.active{
              .pizzaxxsheading{
                  color: $orange;
              }
              .plus-icon{
                  border-color: $orange;
                  &::after{
                      display: none;
                  }
                  &::before{
                      background: $orange;
                  }
              }
          }
          &:hover{
              .pizzaxxsheading{
                  color: $orange;
              }
              .plus-icon{
                  border-color: $orange;
                  &::before, &::after{
                      background: $orange;
                  }
              }
          }
      }
  }
  @include breakpoint(xs){
    width: 100%;
  }
}



.inner-main{
  padding-left: 250px;
  .container{
    padding: 0 60px;
  }
  @include breakpoint(sm){
    padding: 0px;
    display: block;
  }
}
.leadership-banner{
  padding-top: 15px;
  background: $graybg;
}

.investors-info-center{
  .banner-wrapper{
    .banner-content-q{
      .banner-investor-row{
        padding-top: 30px;
        img{
          display: block;
          width: 300px;
          margin: 0px auto;
          @include breakpoint(xs){
            margin:10px auto;
            width: 60%;
          }
        }

      }
    }
  }
}

.shareperformance{
  .pizzasmheading{
    text-align: center;
    font-weight: bold;
    line-height: 30px;
    color: $innerfont;
  }
  .heading-box{
    padding: 50px 0px;
    .pizzaxlheading{
      position: relative;
      &:after{
        content: "";
        position: absolute;
        width: 52px;
        height: 1px;
        background: $orange;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }
  }

  .performence-wraper{
    .pizzasmheading{
      text-align: left;
    }
    .reality-crop-wraper{
      p{
        margin-top:15px;
        strong{
          font-weight: bold;
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
    .chart-wraper img{
      display: block;
      margin-top: 15px;
    }
    .audio-wraper{
      audio{
        display: block;
        width: 65%;
        margin: 30px auto 0px auto;
        &:focus{
          outline: none;
        }
      }
      p{
        margin-top: 15px;
        text-align: center;
        font-weight: bold;
      }
      @include breakpoint(xs){
          audio{
            width: 100%;
          }
      }
    }
  }

  @include breakpoint(xs){
    .heading-box{
      padding: 30px 0px;
    }
    .chart-wraper{
      margin-top: 30px;
    }

  }
}

.investor-news{
  background: $lightgraybg;
  .about-accordion{
    max-width: 70%;
    margin:0px auto;
    margin-top: -45px;
  }
  .inquires{
    font-weight: bold;
    text-align: center;
    margin-top: 70px;
    a{
      font-weight: 400;
      color: $orangefont;
      &:hover{
        color: $innercontent;
      }
    }
  }

  @include breakpoint(xs){
    .about-accordion{
      max-width: 100%;
      margin-top: -30px;
      .inquires{
        margin-top: 30px;
      }
    }
  }
}

.about-our-team{
  background: $graybg;
  .row{
    margin: 0px;
  }
  .p-0{
    padding: 0px;
  }

  .about-box{
    display: block;
    position: relative;
    &:after{
      content:" ";
      position: absolute;
      top:0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background: rgba(0,0,0, 0.4);
    }
    &:hover{
      &:after{
        background: rgba(0,0,0, 0.2);
      }
    }
    .ledrship-overlay{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      z-index: 1;
      padding: 10px;
      .heading-box{
        .pizzaxlheading{
          color: $white;
        }
      }
    }
  }
}

.aboutthedough{
  background: $lightgraybg;
  .heading-box{
    .pizzaxlheading{
      margin-bottom: 20px;
      padding-bottom: 20px;
      position: relative;
      &:after{
        content: "";
        position: absolute;
        width: 52px;
        height: 1px;
        background: $orange;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }
  }
}

.box-inner{
  display: block;
  padding: 30px 20px;
  background: $white;
  cursor: pointer;
  &:hover{
    background: #d7dbde;
  }
  .icon-bx{
    width: 40px;
    margin:0px auto 20px auto;
    display: block;
  }
  .pizzasmheading{
    text-align: center;
    font-family: $proximablack;
    color:$innerfont;
    margin-bottom: 15px;
  }
  p{
    text-align: center;
    margin-bottom: 20px;
  }
  .arrow-right{
    width: 30px;
    margin:0px auto;
    display: block;
  }

  @include breakpoint(lg){
    p{
      height:38px;
    }
  }
  @include breakpoint(md){
    margin-bottom: 20px;
    background: #d7dbde;
    p{
      height: auto;
    }
  }

}
.email-alert-section{
  .row{
    align-items: center;
  }
  .email-content-wraper{
    text-align: center;
  }
  .form-alert{
    text-align: center;
    .input-box-c{
      margin-bottom: 42px;
      &:last-child{
        margin-bottom: 0px;
      }
      label{
        margin-bottom: 5px;
        span{
          color: $orangefont;
          font-weight: bold;
          display: inline-block;
          font-size: 18px;
          font-family: $proximablack;
        }
        }
        input{
          display: block;
          width: 100%;
          border: none;
          background: $lightgraybg;
          border-bottom: 2px solid $grayborder;
          padding: 15px 15px;
          margin-bottom: 15px;
          &:focus{
            outline: none;
            border-bottom: 2px solid $orangeborder;
          }         ;
        }
        .pizzaxxsheading{
          font-family: $proximablack;
          color: $innerfont;
          margin:20px 0px;
        }
        p{
          font-family: $proximablack;
          color: $innerfont;
          margin:20px 0px;
          text-align: center;
        }
        button{
          padding: 15px 40px;
          background: $orange;
          color: $white;
          border:none;
          display: inline-block;
          &:focus{
            outline: none;
          }
        }
        @include breakpoint(xs){
          margin-top: 30px;
          margin-bottom: 0px;
        }
    }
  }
  .inresources{
    padding-top: 70px;
    width: 50%;
    margin: 0px auto;
    .about-accordion{
      .heading-box{
        .pizzaxxsheading{
          color: $innerfont;
          font-weight: bold;
        }
      }
      .bx-coverage{
        padding-top: 30px;
        .pizzaxxsheading{
          color: $innerfont;
          font-weight: bold;
        }
        p{
          a{
            color: $orangefont;
            &:hover{
              color: $innercontent;
            }
          }
        }
      }
    }

    @include breakpoint(xs){
      width: 100%;
      .about-accordion{
        .bx-coverage{
          padding-top: 10px;
          p{
            margin-bottom: 0px;
          }
        }
      }

    }
  }
}

.more-info{
  background: $lightgraybg;
  .heading-box{
    .pizzaxlheading{
      margin-bottom: 20px;
      padding-bottom: 20px;
      position: relative;
      &:after{
        content: "";
        position: absolute;
        width: 52px;
        height: 1px;
        background: $orange;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }
  }
  .box-inner{
    padding: 60px 5px 50px 5px;
  }
}





