/* Common CSS Start */
html,body{
	width:100%;
	height:100%;
	color:$black;
	background:$white;
	font-weight:400;
	position:relative;
	font-size:14px;
	line-height:20px;
	font-family: $proximarg;
}
h1,h2,h3,h4,h5,h6,p,ul,li{
	margin:0;
	font-weight: 400;
}
label,b, strong {
	margin-bottom: 0;
	font-weight: 400;
}
.h1 .small, .h1 small, .h2 .small, .h2 small, .h3 .small, .h3 small, h1 .small, h1 small, h2 .small, h2 small, h3 .small, h3 small{
	font-size:100%;
	color: $black;
	font-weight: 400;
}
label{
	width:100%;
	float:left;
}
*{
	margin:0;
	padding:0;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
small{
	font-size:100%;
}
ul,li{
	list-style:none;
	padding: 0;
	margin: 0;
}
img{
	max-width:100%;
  height: auto;
}
a{
	text-decoration:none;
}
a,i{
	color:$black;
}
a{
	display:inline-block;
}
a:focus{
	text-decoration:none!important;
	outline:0!important;
}
a:hover{
	text-decoration:none;
}
header,footer,section,artical{
	display:block;
}
select {
   -webkit-appearance: none;
   -moz-appearance:    none;
   appearance:         none;
}
textarea{
	resize:none;
}
input[type="text"]:focus{outline:0;}
input[type="email"]:focus{outline:0;}
input[type="submit"]:focus{outline:0;}
select:focus{outline:0;}
textarea:focus{outline: 0;}
.overflow{
	overflow: hidden!important;
}


@keyframes bannerlistanimation {
	from {width: 0px;}
	to {width: 100%;}
  }
  
.slick-prev{
	left: -60px;
	@include breakpoint(lg) {
        left: -30px;
	}
}
.slick-next{
	right: -60px;
	@include breakpoint(lg) {
        right: -30px;
	}
}

.slick-prev,.slick-next{
	border: 0;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	-o-border-radius: 50%;
	-ms-border-radius: 50%;
	width: 35px;
	height: 35px;
	top: 50%;
	background: url(../assets/images/sprite-image.png) no-repeat #fff;
	box-shadow: 0 5px 12px rgba(0,0,0,.12);
	background-position: 8px 8px;
    background-size: 110px;
	@include breakpoint(lg) {
        width: 35px;
		height: 35px;
		background-position: 0px 0px;
		background-size: 110px;
	}
}
.slick-next{
	background-position: 8px -19px;
	@include breakpoint(lg) {
		background-position: 10px 26px;
	}
}
.slick-prev::before, .slick-next::before {
	display:none;
}
.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus{
	// background: url(../assets/images/sprite-image.png) no-repeat;
	background-position: 2px -57px;
	background-size: 300px;
	@include breakpoint(lg) {
		background-position: 2px -44px;
		background-size: 220px;
	}
}
.slick-next:hover, .slick-next:focus{
	background-position: -62px -57px;
	background-size: 300px;
	@include breakpoint(lg) {
		background-position: -46px -44px;
		background-size: 220px;
	}
}
.slick-dotted.slick-slider{
	margin-bottom:0;
}
.slick-dots {
	left: 50%;
	bottom: 70px;
	width: 100%;
	transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
}
.slick-dots li button{
	background: #d0cdcc;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	-o-border-radius: 50%;
	-ms-border-radius: 50%;
	padding:0;
}
.slick-dots li{
	width: 12px;
	height: 12px;
	margin: 0 7px;
}
.slick-dots li button {
	width: 13px;
	height: 13px;
    background: $black;
    border: 2px solid $white;
}
.slick-dots li button::before{
	display:none;
}
.slick-dots li.slick-active button{
	background:$orange;
	width: 16px;
	height: 16px;
}

input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: $black;
}
input::-moz-placeholder { /* Firefox 19+ */
	color: $black;
	opacity:1;
}
input:-ms-input-placeholder { /* IE 10+ */
	color: $black;
}
input:-moz-placeholder { /* Firefox 18- */
	color: $black;
}
input:disabled {
	opacity:0.25;
}

.homepage-wraper{content-visibility: auto;}

.form-control:focus{background: transparent;outline:0;border-color:transparent; box-shadow: 0 0 0;}
.form-control::placeholder { color: $black; opacity: 1;}
.form-control::-ms-input-placeholder {color: $black;}
.pizza-btn {cursor: pointer; font-size: 16px; text-transform: uppercase; font-family: $proximarg; text-align: center; font-weight: bold; border: 0; border-radius: 5px; border:0px solid $orange; color: $white;background: $orange;padding: 10px 30px;display: inline-block;position: relative;overflow: hidden;line-height: 1.4;text-decoration: none;transition: ease-in-out .5s;-webkit-transition: ease-in-out .5s;-moz-transition: ease-in-out .5s;-ms-transition: ease-in-out .5s;}
.pizza-btn:hover, .submit-btn:hover {color: $white; background:$pink; text-decoration: none;}
.pizza-btnbg{background: $orange; border-color: transparent; color: $white;}
.pizza-btnbg:hover{background: transparent; border-color: $orange; color: $orange;}
.pizzabtn-yellow{background:$orange; color: $white;}
.pizzabtn-yellow:hover{background:$white; color: $orange;}
.pizza-img-btn{width: 40px; height: 40px; border-radius: 50%; background: $orange; padding: 7px;}
.seeall-btn{font-size: 18px; font-family: $proximarg; font-weight: bold; color: $brownfont; line-height: 1.4;text-decoration: none;transition: ease-in-out .5s;-webkit-transition: ease-in-out .5s;-moz-transition: ease-in-out .5s;-ms-transition: ease-in-out .5s;}
.seeall-btn{
	span{
		background: url(../assets/images/sprite-image.png) no-repeat $orangefont;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		position: relative;
		background-position: 4px -38px;
		background-size: 32px;
		display: inline-block;
		top: 4px;
		margin-left: 10px;
	}
	@include breakpoint(xs) {
		font-size: 16px;
	}
}
.seeall-btn:hover{color: $pink;}
/* Common CSS End */
.pizzaxlheading{
	font-size: 46px; font-family: $proximablack; font-weight: 900; color:$white;
	@include breakpoint(lg) {
        font-size: 40px;
	}
	@include breakpoint(xs) {
        font-size: 30px;
		margin-bottom: 30px;
	}
}
.pizzalgheading{
	font-size: 30px;color:$black; font-weight: bold; margin-bottom: 50px;
	@include breakpoint(lg) {
        font-size: 30px;
	}
	@include breakpoint(xs) {
        font-size: 24px;
		margin-bottom: 20px;
	}
}
.pizzamdheading{
	font-size: 22px;
    @include breakpoint(lg) {
        font-size: 20px;
	}
}
.pizzasmheading{
	font-size: 20px;
	@include breakpoint(lg) {
        font-size: 18px;
	}
}
.pizzaxsheading{
	font-size: 18px;
	@include breakpoint(lg) {
        font-size: 16px;
	}
}
.pizzaxxsheading{
	font-size:16px;
	@include breakpoint(lg) {
        font-size: 16px;
	}
}
.full-link {position: absolute; left: 0; top: 0; width: 100%; height: 100%; z-index: 5; text-decoration: none !important;}

.container{
	width: 100%;
    max-width: 90%;
	@include breakpoint(xlg) {

	}
	@include breakpoint(lg) {
		max-width: 95%;
	}
	@include breakpoint(md) {

	}
	@include breakpoint(sm) {

	}
	@include breakpoint(xs) {
		padding: 0 15px;
		max-width: 100%;
	}
}
.form-control{
	border: 1px solid $grayborder;
    height: 50px;
    font-size: 16px;
    font-weight: 400;
	color: $black;
    font-family: $proximarg;
    padding: 0px 20px;
    border-radius: 0;
}
.homeslider{
    .slick-dots{
		bottom: -24px;
		left: 50%;
		z-index: 1;
		li {
			margin: 0px 2px;
			width: 57px;
			&:last-child{
				margin-right: 0px;
			}
			&:first-child{
				margin-left: 0px;
			}
			button{
				width: 100%;
				background: #d8e6ed;
				border-radius: 0px;
				border: none;
				height: 5.4px;
			}
			&.slick-active {
				button{
					
					position: relative;
					&:after{
						content: " ";
						position: absolute;
						left: 0px;
						background: #EF5A0D;
						width: 0px;
						bottom: 0px;
						top: 0px;
						animation: 5s ease-in-out forwards bannerlistanimation;
					}
				}
			}
		}
    }
	@include breakpoint(sm) {
		.slick-dots{
			bottom: -40px;
			left: 50%;
			z-index: 1;
		}
	}
	@include breakpoint(xs) {
		.slick-dots{
			bottom: -40px;
			left: 50%;
			z-index: 1;
		}
	}
}

.justslider, .alsosider, .alsolikeslider, .redeem-slider-wraper{
	.slick-prev,.slick-next{
		background: url(../assets/images/sprite-image.png) no-repeat #fff;
		box-shadow: 0 5px 12px rgba(0,0,0,.12);
	}
	.slick-prev, .slick-prev{
		background-position: 7px 9px;
		left: 30px;
		z-index: 1;
	}
	.slick-next, .slick-next{
		right: 20px;
		background-position: 7px -18px;
		z-index: 1;
	}
	@include breakpoint(xs) {
		.slick-prev{
			left: 10px;
		}
		.slick-next{
			right: 7px;
		}
	}
}
.redeem-slider-wraper{
	.slick-prev, .slick-next{
		top: -30px;
	}
	.slick-prev{
		left: auto;
		right: 70px;
	}
}
.alsosider{
	@include breakpoint(xs) {
		.slick-next{
			right: 15px;
		}
	}
}
.alsolikeslider{
	.slick-prev{
		left: -40px;
	}
	.slick-next{
		right: -40px;
	}
}
.homeslider{
	.slick-prev, .slick-next{
		width: 50px;
		height: 50px;
		background: url(../assets/images/sprite-image.png) no-repeat $orangefont;
		box-shadow: 0 0 0;
	}
	.slick-prev{
		background-position: 7px -48px;
		left: 20px;
		z-index: 1;
	}
	.slick-next{
		background-position: 10px -90px;
		right: 20px;
		z-index: 1;
	}
}

.justslider{
	.slick-list{
		padding-right: 300px;
	}
	@include breakpoint(md) {
		.slick-list{
			padding-right: 150px;
		}
	}
	@include breakpoint(xs) {
		.slick-list{
			padding-right: 100px;
		}
		.slick-prev, .slick-next{
			width: 30px;
			height: 30px;
		}
		.slick-prev{
			background-position: 5px 6px;
		}
		.slick-next{
			background-position: 5px -21px;
		}
	}
	@include breakpoint(xxs) {
		.slick-list{
			padding-right: 70px;
		}
	}
}
.alsosider{
	.slick-list{
		padding-right: 200px;
	}
	@include breakpoint(xs) {
		.slick-list{
			padding-right: 80px;
		}
	}
}
.alsolikeslider{
	.slick-list{
		padding-right: 200px;
	}
	@include breakpoint(md) {
		.slick-list{
			padding-right: 50px;
		}
	}
	@include breakpoint(xs) {
		.slick-list{
			padding-right: 80px;
		}
	}
}
.back-btn{
    font-size: 16px;
    color: $black;
    font-family: $proximarg;
    font-weight: 600;
    padding-left: 25px;
    position: relative;
    &::before{
        content: "\f104";
        position: absolute;
        pointer-events: none;
        font-family: "FontAwesome";
        left: 0;
        top: -1px;
        cursor: pointer;
        width: 12px;
        height: 18px;
        display: block;
        font-size: 22px;
        color: $orange;
        transition: 0.3s;
        line-height: 20px;
        text-align: center;
        font-weight: 500;
    }
	&:hover{
		color: $orange;
	}
}
.register-open{
    .register-popup{
        top: 0 !important;
    }
}
.edit-open{
    .editprofile-popup{
        top: 0 !important;
    }
}

.password-open{
	.changepassword-popup, .size-popup{
		display: block !important;
	}
}
.Store-open{
	.store-popup{
		display: block !important;
	}
}
.Find-open{
	.find-popup{
		display: block !important;
	}
}
.deleteSavedStores{
	.find-popup2{
		display: block !important;
	}
}
.deleteSavedcard{
	.deletecard-popup{
		display: block !important;
	}
}
.outstockpopupshow{
	.out-of-stock{
		display: block !important;
	}
}

.successshow{
	.success-popup{
		display: block !important;
	}
}
.delete-open{
	.delete-popup{
		display: block !important;
	}
}

.storeCloseSavedStores{
	.storeClose-popup{
		display: block !important;
	}
}

.storeCloseSavedStores2{
	.storeClose-popup2{
		display: block !important;
	}
}
.popupblockeropenclose{
	.popupblocker{
		display: block !important;
	}
}
.upgradepopup{
  .find-popup2{
    display: block !important;
  }
}
.doughpopup{
  .find-popup3{
    display: block !important;
  }
}
.cancelpopup{
	.find-popup4{
	  display: block !important;
	}
  }

  .removeCartpopup{
	.find-popup-remove{
	  display: block !important;
	}
  }


.asterisk{color: red; font-size: 16px; position: relative; top: 1px;    display: inline-block !important;}
.edit-open{
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .checkout-accordion-content .also-like .alsolikeslider .slick-prev {
    left: -7px !important;
    width: 23px;
    height: 23px;
    background-position: 1px 4px;
    }
    .checkout-accordion-content .also-like .alsolikeslider .slick-next {
      right: -7px;
      width: 23px;
      height: 23px;
      background-position: 2px -23px;
  }
}

@keyframes spinner {
	to {transform: rotate(360deg);}
  }

  .spinner:before {
	content: '';
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 20px;
	height: 20px;
	margin-top: -10px;
	margin-left: -10px;
	border-radius: 50%;
	border: 2px solid $black;
	border-top-color: $white;
	animation: spinner .8s linear infinite;
  }
.incompleteshow{
	.incomplete-popup{
		display: block !important;
	}
}

.justslider .slick-next, .justslider .slick-prev{
	top:43%;
}
.justslider .slick-next{
    right: -27px;
z-index: 1;
width: 71px;
height: 71px;
    background: url(../assets/images/sprite-image.png) no-repeat #EE5A0D;
    box-shadow: 0 0 0;
	background-position: 3px -88px;
border: 6px solid #fff;
@include breakpoint(xs){
	width: 63px;
    height: 63px;
	background-position: 1px -92px
}
}
.justslider .slick-prev{
    left: -27px;
z-index: 1;
width: 71px;
height: 71px;
    background: url(../assets/images/sprite-image.png) no-repeat #EE5A0D;
    box-shadow: 0 0 0;
    background-position: 23px -44px;
border: 6px solid #fff;
@include breakpoint(xs){
	width: 63px;
    height: 63px;
    background-position: 16px -48px;
}
}
.pro-item{
    position: relative;
}
.darkbg{
    position: absolute;
    top: 20px;
    left: 25px;
    height: 60px;
    width: 250px;
    z-index: 123;
	@include breakpoint(xs){
		width: 70%;
	}
}

.loader-banner{
	height: 200px !important;
}
.inner-footer{
	.add-cart{
		display: none;;
	}
	footer#sitefooter{
		padding-left: 250px;
		padding-bottom: 0px;
		.store-locator{
			display: none;
		}
		.social-icons{
			display: none;
		}
		.footer-bottom{
			display: none;
		}
		@include breakpoint(sm){
			padding-left: 0px;
		}
		@include breakpoint(xs){
			
			.footer-left{
				width: 100%;
				text-align: center;
    			padding-bottom: 30px;
			}
			.footer-right{
				width: 100%;
			}
		}
	}	
}

.footer-cart-h{
	.add-cart{
		display: none;;
	}
}

.error-green-show{
	#errorforyou{
	  color: green;
	}
  }
  .error-red-show{
	#errorforyou{
	  color: red;
	}
  }

  .hiddenScroll{overflow: hidden;}

.inner-header{
.logo{
	a{
		width: 90px;
		cursor: pointer;
	}
}
}


@include breakpoint(xs){
	.mobile-header-about{
		.row{
			align-items: center;
			a{
				width: 85px !important;
			}
		}
	}
	.hidden-scroll-body{
		overflow: hidden;
	}
}

